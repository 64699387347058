import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loginc',
  templateUrl: './loginc.component.html',
  styleUrls: ['./loginc.component.scss']
})
export class LogincComponent {


  constructor(private rt:Router) { }

  userName: any;
  userPass: string;
  countryName: string;
  msg: string; 

  selectCountry(country) {
    const flags = document.querySelectorAll('.flag');
    flags.forEach(flag => {
      flag.classList.remove('selected');
    });
  
    const selectedFlag = document.querySelector(`[alt="${country} flag"]`);
    selectedFlag.classList.add('selected');
  
    this.countryName = country;
  }
  
  onSubmit() {
    // Access the values of userName and userPass here
    console.log('Username: ' + this.userName);
    console.log('Password: ' + this.userPass);
    console.log('Country Name: '+ this.countryName)

    if(this.countryName == null){

      alert("Please select Country");
    }
    else{
    //Canada Area
    if( this.countryName == "Canada" && this.userName == "mmahmood@x2x-ecommerce.com" || this.userName  == "info@newways.com")
    {     
          console.log("canada area ")
          if(this.userName == "mmahmood@x2x-ecommerce.com" && this.userPass == "123"){
            
            localStorage.setItem('isLoggedIn', "true");
            localStorage.setItem('user','userca');
            localStorage.setItem('cname','X2X E-commerce');

              this.rt.navigate(['/shope/collection/left/sidebar']);
          }
          if(this.userName == "info@newways.com" && this.userPass == "123"){
            
            localStorage.setItem('isLoggedIn', "true");
            localStorage.setItem('user','usercb');
            localStorage.setItem('cname','New Ways (Pvt) Ltd');

              this.rt.navigate(['/shope/collection/left/sidebar']);
          }

    }
      

  else if(this.countryName == "USA" && this.userName == "m.inc@gmail.com" || this.userName == "rozen@gmail.com")
  {
    console.log("USA area ")
    if(this.userName == "m.inc@gmail.com" && this.userPass == "123"){
      
      localStorage.setItem('isLoggedIn', "true");
      localStorage.setItem('user','userua');
      localStorage.setItem('cname','Matt Inc');
        this.rt.navigate(['/shope/collection/left/sidebar']);   
    }
    if(this.userName == "rozen@gmail.com" && this.userPass == "123"){
      
      localStorage.setItem('isLoggedIn', "true");
      localStorage.setItem('user','userub');
      localStorage.setItem('cname','Anabell Rozen');
        this.rt.navigate(['/shope/collection/left/sidebar']);
    }
  }
      else
      {
       
        alert( "User not exist in this country");
      }
    // You can perform any further actions like form submission to a server here.
  }
}
}
