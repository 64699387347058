<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">sale</span>
    </div>
    <div class="front">
       <!-- <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">  -->
        <img 
          [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
          [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src" 
          class="img-fluid lazy-loading" 
          alt="{{ product.images[0].alt }}" />
      <!-- </a> -->
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <!-- <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]"> -->
        <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading" alt="{{ product.images[1].alt }}">
      <!-- </a> -->
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src">
        </a>
      </li>
    </ul>
    <!-- <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"
        *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)"
        *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div> -->
  </div>
  <div class="product-detail">
    <div>
      <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
       
                                                      <!-- Add an input field for quantity -->
                                                    
                                                      <div class="form-group mt-2">
                                                       
                                                        <div class="input-group" style="border: 1px solid grey !important; border-radius: 5px;">
                                                          <span class="input-group-prepend">
                                                            <button class="btn  btnp" type="button" (click)="decrementQuantity(product,product.mo)" id="decrement">-</button>
                                                          </span>
                                                               <input type="number" class="form-control text-center fld" id="quantity" (focus)="checkcurrentqty(quantity)" (focusout)="setqty(product,quantity)" name="quantity" [(ngModel)]="quantity" min="{{product.moq}}" >
                                                          
                                                          <span class="input-group-append">
                                                            <button class="btn  btnp" type="button"  (click)="incrementQuantity(product,product.mo)" id="increment">+</button>
                                                          </span>
                                                          <!-- <span class="input-group-append">
                                                            <button class="btn " type="button"  (click)=" bntcart(product)" > <i class="ti-shopping-cart"></i></button>
                                                          </span> -->
                                                        </div>
                                                      </div>
                                                   
                                                      
                                                      <!-- Add a button to add the product to the cart -->
                                                      
      <!-- <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]" > -->
        <h6 class="mt-2" style="color: red;"> MOQ {{product?.moq }} X {{product?.mo}}</h6>
        <h6 class="mt-2">{{ product?.title | titlecase }}</h6>
      <!-- </a> -->
      <p>{{ product?.description }}</p>
      <h4>
        {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
        <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
      </h4>
      <!-- <ul class="color-variant" *ngIf="Color(product?.variants).length">
        <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
          (click)="ChangeVariants(color, product)">
        </li>
      </ul> -->
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

